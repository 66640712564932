import React from 'react';
import {Link} from 'react-router-dom';

class Header extends React.Component {
    render() {
        return (
            <header>
                <Link to="/">
                    <img src="/logo.png" alt="logo" title="logo" />
                </Link>
            </header>
        );
    }
}

export default Header;