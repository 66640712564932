import React from "react";
import '../scss/ageChoice.scss';
import {Link} from 'react-router-dom';
import dataHelper from '../../helpers/data';
import Header from './header';

function Tour({title, img, index, videos}) {
    return (
        <Link to={`/tour-overzicht/${index}`}>
            <div className={`${title} picBox`}>
                <img className="smallImage float-left" src={img} alt={`image-${index}`}/>
                <div className="text-container">
                    <h2 className="smallText">
                        {title}
                    </h2>
                    <p>{videos.length} stops</p>
                </div>
                <img src="/arrow-right.svg" alt="arrow right" title="arrow right" />
            </div>
        </Link>
    );
}

class AgeChoice extends React.Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="container Choice">

                    {dataHelper.getTours().map((tour, i) => {
                        return (
                            <div className="row">
                                <Tour {...tour} index={i} key={i}/>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default AgeChoice;