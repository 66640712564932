import React from 'react';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: this.props.history.location.pathname.startsWith('/numpad') ? 1 : 0,
		};
	}

	handleToggleActive = (num) => {
		this.props.history.push(num === 0 ? `/tour-overzicht/${this.props.match.params.tourId}` : `/numpad/${this.props.match.params.tourId}`);
	}
    render() {
        return (
            <footer>
            	<div className="footer-inner container">
            		<button className={this.state.active === 0 ? 'active' : ''} onClick={() => { this.handleToggleActive(0); }}>
            			<img src="/list.svg" alt="list" title="list" />
            			<img className="active" src="/list-blue.svg" alt="list blue" title="list blue" />
            			<span>Lijst</span>
            		</button>
            		<button className={this.state.active === 1 ? 'active' : ''} onClick={() => { this.handleToggleActive(1); }}>
            			<img src="/keypad.svg" alt="toetsenblok" title="toetsenblok" />
            			<img className="active" src="/keypad-blue.svg" alt="keypad blue" title="keypad blue" />
            			<span>Toetsenblok</span>
            		</button>
            	</div>
            </footer>
        );
    }
}

export default Footer;