import data from '../data.json';

/**
 * @name getTours
 * @returns {Array} - returns an array of tours
 */
export function getTours() {
    return data;
}

/**
 * @name getTourByIndex
 * @param {number} i - Index of tour in tour array
 * @returns {Object} - tour
 */
export function getTourByIndex(i) {
    return data[i];
}

/**
 * @name getTourVideos
 * @param {number} i - Index of tour in tour array
 * @returns {Array} - return an array of videos
 */
export function getTourVideos(i) {
    return data[i].videos;
}

/**
 * @name getTourVideoByIndex
 * @param {number} i - Index of tour in tour array
 * @param {number} y - Index of video in videos array
 * @returns {Object} - video
 */
export function getTourVideoByIndex(i, y) {
    return data[i].videos[y];
}

export default {
    getTours,
    getTourByIndex,
    getTourVideos,
    getTourVideoByIndex,
};
