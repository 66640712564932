import React from 'react';
import './assets/scss/main.scss';
import Header from './assets/inc/header';
import AgeChoice from "./assets/inc/ageChoice";
import {Switch, BrowserRouter as Router, Route} from 'react-router-dom';
import Numpad from "./assets/inc/numpad";
import TourOverview from "./assets/inc/TourOverview";
import VideoOverview from "./assets/inc/VideoOverview";
import ScrollToTop from "./assets/inc/scrolltoTop";

function App() {
    return (
        <div className="App">
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/Numpad/:tourId" component={Numpad}/>
                        {/* Route naar Pagina's */}
                        <Route exact path="/" component={AgeChoice}/>
                        <Route exact path="/tour-overzicht/:tourId" component={TourOverview}/>
                        <Route exact path="/tour-overzicht/:tourId/video/:videoId" component={VideoOverview}/>
                    </Switch>
                </ScrollToTop>
            </Router>
        </div>
    );
}

export default App;