import React, { createRef } from "react";
import { Link } from "react-router-dom";
import {getTourByIndex} from '../../helpers/data';
import Footer from './footer';

const numPad = [
    [7, 8, 9],
    [4, 5, 6],
    [1, 2, 3],
    ['C', 0,'OK'],
];

class Numpad extends React.Component {

    constructor(props) {
        super(props);
        this.tourId = props.match.params.tourId;
        this.tour = getTourByIndex(this.tourId);
        this.inputRef = createRef();
        this.i = 0;
        this.originalValue = this.tour.title.toLowerCase().includes('volwassen') ? 'V00' : 'K00';
    }

    handleNumClick = (input, row, index) => {
        if (typeof input === "number") {
            // Voeg nummer toe aan tekst input
            if (this.i < 2) {
                if (this.i === 0) {
                    this.inputRef.current.value = this.originalValue.substr(0, 2) + input;
                } else if (this.i === 1) {
                    const aNum = this.inputRef.current.value.substr(2, 2);
                    this.inputRef.current.value = this.originalValue.substr(0, 1) + aNum + input;
                }
                this.i++;
            }
            return;
        }
        if (typeof input === "string") {
            if (input === "C") {
                // Clear tekst input
                this.inputRef.current.value = this.originalValue;
                this.i = 0;
            } else if (input === "OK" && this.inputRef.current.value.length > 0 && this.i >= 1) {
                // Submit
                const vid = this.tour.videos.find(v => v.num === this.inputRef.current.value);
                if (vid) {
                    this.props.history.push(`/tour-overzicht/${this.tourId}/video/${vid.num}`);
                } else {
                    alert("Er bestaat geen video in de huidige tour met het nummer: " + this.inputRef.current.value);
                }
            }
        }
    };

    render() {
        return (
            <div style={{ height: '100%' }}>
                <div className="container numpad-container">
                    <div className="title-header">
                        <Link to={`/tour-overzicht/${this.tourId}`} className="backButton">
                            <img src="/arrow-blue.svg" title="blue back icon" ref="blue back icon" />
                            <span>Terug</span>
                        </Link>
                        <h1>{this.tour.title}</h1>
                    </div>
                    <input readOnly ref={this.inputRef} type="text" value={this.originalValue} />
                    <div className="numPad container">
                        {numPad.map((r, i) => (
                            <div className={`numRow numRow-${i}`} key={i}>
                                {r.map((n, y) => (
                                    <div onClick={() => { this.handleNumClick(n, i, y); }} key={y} className={`numKey numKey-${n}`}>
                                        <div>
                                            <span>{n}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="numpad-footer"><Footer {...this.props} /></div>
            </div>
        );
    }
}

export default Numpad;