import React from 'react';
import {Link} from "react-router-dom";
import { Vimeo } from 'vimeo';
import config from '../../config';
import ShowNumpad from "./showNumpad";
import Footer from './footer';
import {getTourByIndex} from '../../helpers/data';

function VideoLink({title, thumbnail, index, num}) {
    return (
        <Link className="linkVideo" to={`${window.location.pathname}/video/${num}`}>
            <div className="smallImage" style={{ backgroundImage: 'url(' + thumbnail + ')' }} />
            <div className="text-container">
                <h2 className="linkVideoText">{title}</h2>
                <span>{num}</span>
            </div>
            <img src="/arrow-right.svg" alt="arrow right" title="arrow right" />
        </Link>
    );
}

export default class TourOverview extends React.Component {
    constructor(props) {
        super(props);
        this.tourId = props.match.params.tourId;
        this.tour = getTourByIndex(this.tourId);
        this.tour.videos = this.tour.videos.map((v, i) => ({ ...v, index: i + 1, placeholder: '', title: '' }));
        this.state = {
            q: '',
            videos: this.tour.videos,
            loading: true,
        };
    }

    componentWillMount() {
        const client = new Vimeo(config.vimeo.CLIENT_ID, config.vimeo.CLIENT_SECRET, config.vimeo.ACCESS_TOKEN);
        client.request({
            method: 'GET',
            path: `/videos?uris=${this.tour.videos.reduce((acc, vid, i) => {
                acc += '/videos/' + vid.id;
                if (this.tour.videos.length > i + 1) acc += ',';
                return acc;
            }, '')}`,
            query: {
                fields: 'pictures, uri, name'
            }
        }, (error, body, status_code, headers) => {
            if (error) {
                console.log(error);
            }
            if (body && !error && body.data) {
                this.setState({
                    videos: body.data.map((vimeoData) => {
                        const splitUri = vimeoData.uri.split('/');
                        const id = parseInt(splitUri[splitUri.length - 1], 10);
                        const video = this.tour.videos.find(v => v.id === id);
                        video.thumbnail = vimeoData.pictures.sizes[1].link;
                        video.title = vimeoData.name;
                        return video;
                    }),
                    loading: false,
                });
            }
        });
    }

    handleSearch = (ev) => {
        const q = ev.target.value;
        const videos = this.tour.videos.filter((video) => {
            return video.title.toLowerCase().includes(q.toLowerCase()) || video.num.toLowerCase().includes(q.toLowerCase());
        });
        this.setState({ q, videos });
    }

    handleSubmit = (ev) => {
        return false;
        const vidId = parseInt(this.state.q, 10);
        if (!Number.isNaN(vidId)) {
            const video = this.tour.videos.find(v => v.index === vidId);
            if (video) {
                this.props.history.push(`/tour-overzicht/${this.tourId}/video/${video.index - 1}`);
            }
        }
        ev.preventDefault();
        ev.stopPropagation();
    }

    render() {
        return (
            <div>
                <div className="container tour-overview">
                    <div className="title-header">
                        <Link to="/" className="backButton">
                            <img src="/arrow-blue.svg" title="blue back icon" ref="blue back icon" />
                            <span>Terug</span>
                        </Link>
                        <h1>{this.tour.title}</h1>
                    </div>
                    <div className="search-container">
                        <input type="search" name="q" value={this.state.q} onChange={this.handleSearch} />
                        {!this.state.q ? (
                            <div className="placeholder">
                                <img src="/search.svg" alt="vergrootglas" title="vergrootglas" />
                                <span>Zoeken</span>
                            </div>
                        ) : null}
                    </div>

                    {this.state.loading ? <div className="loader" /> : this.state.videos.map((video, i) => {
                        return (
                            <div className="row">
                                <VideoLink {...video} index={video.index} key={i}/>
                            </div>
                        );
                    })}
                    <br /><br />
                </div>
                <Footer {...this.props} />
            </div>
        );
    }
}
