import React from 'react';
import { Vimeo } from 'vimeo';
import { Link } from 'react-router-dom';
import config from '../../config';
import {getTourVideoByIndex, getTourByIndex} from '../../helpers/data';
import ReactPlayer from "react-player";
import "../scss/videoPages.scss";

export default class VideoOverview extends React.Component {
    constructor(props) {
        super(props);
        const {tourId, videoId} = props.match.params;
        this.tour = getTourByIndex(tourId);
        this.tourId = tourId;
        this.videoId = videoId;
        this.video = this.tour.videos.find(v => v.num === videoId);
        this.state = {
            title: '',
        };
    }

    componentWillMount() {
        if (this.video && this.video.id) {
            const client = new Vimeo(config.vimeo.CLIENT_ID, config.vimeo.CLIENT_SECRET, config.vimeo.ACCESS_TOKEN);
            client.request({
                method: 'GET',
                path: `/videos/${this.video.id}`,
                query: {
                    fields: 'name'
                }
            }, (error, body, status_code, headers) => {
                if (error) {
                    console.log(error);
                }
                if (body && !error && body.name) {
                    this.setState({ title: body.name });
                }
            });
        }
    }

    render() {
        const {tourId} = this.props.match.params;
        return (
            <div className="container video-overview-container">
                <div className="title-header">
                    <Link to={`/tour-overzicht/${this.tourId}`} className="backButton">
                        <img src="/arrow-blue.svg" title="blue back icon" ref="blue back icon" />
                        <span>Terug</span>
                    </Link>
                    <h1>{this.state.title}</h1>
                </div>
                {this.video ? (
                    <div className="video-container">
                        <iframe src={`https://player.vimeo.com/video/${this.video.id}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    </div>
                ) : (
                    <p>De opgevraagde video bestaat niet.</p>
                )}
            </div>
        );
    }
}